<template>
  <Datatable v-bind:entries="accounts" :columns="columns" :title="'Accounts'"/>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import { isAdmin, getCapability } from '../_helpers'
  import Datatable from '../components/Datatable.vue'
  export default {
    computed: {
        ...mapState('accounts', ['accounts']),
        ...mapState('users', ['user']),
        columns(){
          return [
          {name: 'account_name', text: 'Account Name'},
          {name: 'account_code', text: 'Account Code'},
          {name: 'account_officer', text: 'Account Officer'},
          {name: 'type', text: 'Type',
            raw: {
              type: 'Text',
              fields: [
                {value: '1', text: 'Company'},
                {value: '2', text: 'Individual'},
              ]
            }
          },
          {name: 'email', text: 'Email'},
          {name: 'contact_person', text: 'Contact Person'},
          {name: 'contact_phone', text: 'Contact Phone'},
          {name: 'parent', text: 'Parent Account',
            raw: {
              type: 'Sub',
              value: 'account_name'
            }
          },
          {name: 'status', text: 'Status',
            raw: {
              type: 'Text',
              fields: [
                {value: '1', text: 'Active'},
                {value: '2', text: 'Inactive'},
              ]
            }
          },
          {name: 'action', text: 'Action',
            raw: {
              type: 'Action',
              fields: [
                getCapability('edit_account', this.user) ? {event: 'edit_account', text: 'Edit', method: 'get', class: 'btn btn-primary'} : {},
                getCapability('delete_account', this.user) ? {event: 'accounts/destroy', text: 'Delete', method: 'post', class: 'btn btn-danger ml-1'} : {},
                isAdmin(this.user) ? {event: 'edit_account_capability', text: 'Edit Capability', method: 'get', class: 'btn btn-warning ml-1'} : {},
              ]
            }
          },
        ]
      }
    },
    components:{
      Datatable,
    },
    methods: {
      ...mapActions('accounts', ['get_all']),
    },
    created() {
      this.$emit('onChildInit', 'Accounts', getCapability('add_account', this.user) ? '/accounts/add' : '')
      this.get_all()
    },
  }
</script>
