<template>
  <div class="row">
    <div class="col-sm-12">
      <form @submit.prevent="handleSubmit">
        <div class="row">
          <div class="form-group col-sm-12 col-md-12 required">
            <label for="account_id">Account:</label>
            <Select2 name="account_id" v-model.number="account_id" :options="accountOptions"/>
          </div>          
          <div class="form-group col-sm-12">
            <input class="btn btn-primary" required type="submit" value="Show" :disabled="this.onRequest">
          </div>
        </div>
      </form>
    </div>
    <div class="col-sm-12">
      <Datatable v-bind:entries="alerts" :columns="columns" :title="'Alert'"/>
    </div>
    <div v-if="this.onRequest" class="modal-backdrop fade show" style="display:flex; align-items: center; justify-content: center;">
      <img :src="require('../assets/loading.gif')"/>
    </div>
  </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import { getCapability } from '../_helpers'
  import Select2 from 'vue3-select2-component';
  import Datatable from '../components/Datatable.vue'
  export default {
    computed: {
      ...mapState('alerts', ['alerts', 'onRequest']),
      ...mapState('accounts', ['accounts']),
      ...mapState('users', ['user']),
      columns(){
        return [
            {name: 'name', text: 'Name'},
            {name: 'type', text: 'Type'},
            {name: 'account', text: 'Account',
              raw: {
                type: 'Sub',
                value: 'account_name'
              }
            },
            {name: 'protocol', text: 'Protocol'},
            {name: 'driver_behavior_type', text: 'Driver Behavior Type'},
            {name: 'action', text: 'Action',
              raw: {
                type: 'Action',
                fields: [
                  getCapability('edit_alert', this.user) ? {event: 'edit_alert', text: 'Edit', method: 'get', class: 'btn btn-primary'} : {},
                  getCapability('delete_alert', this.user) ? {event: 'alerts/destroy', text: 'Delete', method: 'post', class: 'btn btn-danger ml-1'} : {},
                ]
              }
            },
          ]
        }
    },
    data(){
      return {
        account_id: null,
        accountOptions: [],
      }
    },
    components:{
      Datatable,
      Select2,
    },
    methods: {
      ...mapActions('alerts', ['get_custom_alerts_by_account']),
      ...mapActions('accounts', ['get_all']),
      ...mapActions('alert', ['error', 'clear']),
      handleSubmit(){
        if(this.onRequest) return;
        this.clear()
        this.get_custom_alerts_by_account({account_id: this.account_id})
      },
    },
    created() {
      this.$emit('onChildInit', 'Custom Alerts', getCapability('add_alert', this.user) ? '/alerts/add' : '')
      this.get_all()
    },
    watch: {
      accounts(nextState, prevState){
        if(nextState !== prevState){
          this.accountOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.accountOptions.push({id:item.id, text:item.account_name})
            })
            if(this.accountOptions.length > 0){
              this.account_id = this.accountOptions[0].id
              this.handleSubmit()
            }
          }
          return
        }
      },
    }
  }
</script>
